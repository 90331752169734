
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
// import EditCampana from "./edit-campana.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import Campana from "../../../store/entities/OZONE/campana";
import Exportacion from "../../../store/entities/OZONE/exportacion";
import Transferencia from "../../../store/entities/OZONE/transferencia";
import Importacion from "../../../store/entities/OZONE/importacion";
import WidgetCounter from "../../../components/Muse/Widgets/WidgetCounter.vue";
import EditarTransferencia from "./edit-transferencia.vue";
import moment, { Moment } from "moment";
import XLSX from "xlsx";
import { InputGroupPlugin } from "bootstrap-vue";

@Component({
  components: {
    //EditCampana,
    PeopleFind,
    WidgetCounter,
    EditarTransferencia,
  },
})
export default class ImportTransferencias extends AbpBase {
  fileList = [];
  actions = [];
  transferencias: Array<Transferencia> = [];
  wrongIBANs: Array<string> = [];
  current = 0;
  wrongFormat = false;
  done = false;

  labelCol = { span: 4 };
  wrapperCol = { span: 14 };

  form = {
    name: "",
    desc: "",
  };

  rules = {
    name: [
      {
        required: true,
        message: "El origen es obligatorio",
        trigger: "blur",
      },
    ],
    desc: [
      { min: 0, max: 5000, message: "Máximo 5000 caracteres", trigger: "blur" },
    ],
  };

  steps: { title; content }[] = [
    {
      title: "Cargar Fichero",
      content: "First-content",
    },
    {
      title: "Resumen & Validación",
      content: "Second-content",
    },
    {
      title: "Carga",
      content: "Last-content",
    },
    {
      title: "Finalizado",
      content: "Last-content",
    },
  ];

  validaciones: { validation: string; valid: boolean }[] = [
    {
      validation: "IBAN",
      valid: false,
    },
    {
      validation: "Campaña",
      valid: false,
    },
    {
      validation: "Importe",
      valid: false,
    },
    {
      validation: "Motivo",
      valid: false,
    },
    {
      validation: "Titular",
      valid: false,
    },
  ];
  $store: any;
  $router: any;

  next() {
    this.current = this.current + 1;
  }

  prev() {
    if (this.current == 1) {
      this.transferencias = [];
      this.validaciones = [];
      this.wrongIBANs = [];
      this.fileList = [];
    }

    this.current = this.current - 1;
  }

  reset() {
    location.reload();
  }

  goToTRansferencias() {
    
    this.$router.push({ name: "transferlist" });
  }

  get loading() {
    return this.$store.state.transferencia.loading;
  }

  loadTransfers() {
    (
      this.$refs.ruleForm as Vue & { validate: (valid) => Promise<boolean> }
    ).validate(async (valid) => {
      if (valid) {
        this.actions.push("Inicio importación");
        var importacion = new Importacion();
        importacion.fichero = this.fileList[0].name;
        (importacion.origen = this.form.name),
          (importacion.observaciones = this.form.desc);
        importacion.transferencias = this.transferencias;

        await this.$store
          .dispatch({
            type: "transferencia/importar",
            data: importacion,
          })
          .then(() => {
            this.actions.push("Transferncias cargadas correctamente");
            this.next();
          })
          .catch(() => {
            this.actions.push("No se han podido cargar las transferencias");
          });
      } else {
        return false;
      }
    });
  }

  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }

  beforeUpload(file) {
    this.transferencias = [];
    this.validaciones = [];
    this.wrongIBANs = [];
    this.wrongFormat = false;
    this.fileList = [file];
    console.log(this.fileList);
    this.readTransfers();

    return false;
  }

  get min() {
    return this.transferencias.reduce((prev, curr) =>
      parseFloat(prev.importe.toString()) < parseFloat(curr.importe.toString()) ? prev : curr
    ).importe;
  }

  readTransfers() {

    try {

      if (this.fileList && this.fileList.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const bstr = e.target.result;

        const wb = XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const dataExcel = XLSX.utils.sheet_to_json(ws, { header: 2 });

        dataExcel.forEach((value, index) => {
          var transferencia = new Transferencia();
          transferencia.titular = dataExcel[index]["Titular"];
          transferencia.iban = dataExcel[index]["IBAN"];
          transferencia.importe = dataExcel[index]["Importe"];
          transferencia.motivo = dataExcel[index]["Motivo"];
          transferencia.observaciones = dataExcel[index]["Observaciones"];
          transferencia.campanaId = dataExcel[index]["CampanaID-Ekon"];
          this.transferencias.push(transferencia);
        });

        this.validaciones = [
          {
            validation: "IBAN",
            valid: this.transferencias.every(
              (item) => item.iban && this.isValidIBANNumber(item.iban)
            ),
          },
          {
            validation: "Campaña",
            valid: this.transferencias.every((item) => item.campanaId),
          },
          {
            validation: "Importe",
            valid: this.transferencias.every(
              (item) => item.importe && item.importe > 0
            ),
          },
          {
            validation: "Motivo",
            valid: this.transferencias.every((item) => item.motivo),
          },
          {
            validation: "Titular",
            valid: this.transferencias.every((item) => item.titular),
          },
        ];

        this.wrongFormat = this.validaciones.every((item) => !item.valid);
        if (this.wrongFormat) {
          this.$notification.error({
            message: "Error de Formato",
            description: "Por favor utilice la plantilla!",
          });
        }
      };
      reader.readAsBinaryString(this.fileList[0]);
    }
    }
    catch (e) {
    var mensaje = "E";
  }
    
  }

  isValidIBANNumber(input) {
    var CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
      AL: 28,
      BY: 28,
      EG: 29,
      GE: 22,
      IQ: 23,
      LC: 32,
      SC: 31,
      ST: 25,
      SV: 28,
      TL: 23,
      UA: 29,
      VA: 22,
      VG: 24,
      XK: 20,
    };
    var iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
      digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      this.wrongIBANs.push(input);
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
      return (letter.charCodeAt(0) - 55).toString();
    });
    // final check
    var resto = this.mod97(digits);
    if (resto == 1) {
      return true;
    } else {
      this.wrongIBANs.push(input);
      return false;
    }
  }

  mod97(string) {
    var checksum = string.slice(0, 2),
      fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }
}
